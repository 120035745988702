//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	data() {
		return {
			retryCount: 0,
			maxRetries: 10,
			polling: null, 
		};
	},
	computed: {
		...mapState({
			ppmSubscription: ({ ppmStore: { ppmSubscription } }) => ppmSubscription,
			ppmStatus: ({ ppmStore: { ppmStatus } }) => ppmStatus,
		}),
	},
	watch: {
		'ppmStatus.action': function (newVal) {
			if (newVal === 'wait') {
				this.startPolling();
			} else {
				this.stopPolling();
			}
		},
	},
	methods: {
		startPolling() {
			if (this.polling) return; 
			this.retryCount = 0;
			this.polling = setInterval(async () => {
				if (this.retryCount >= this.maxRetries) {
					this.disconnect('timedout');
					console.warn('Max retries reached, stopping polling.');
					return;
				}
				var phone_stream_id = this.getCookie('phone_stream_id');
				if (phone_stream_id) {
					this.retryCount++;
					this.$store.dispatch('ppmStore/checkPhoneStreamStatus', phone_stream_id);
				}
			}, 10000);
		},
		stopPolling() {
			if (this.polling) {
				clearInterval(this.polling);
				this.polling = null;
				this.retryCount = 0;
			}
		},
		disconnect(type) {
			this.stopPolling();

			let alertObj = {
				type: 'warning',
				message: 'De verbinding is verbroken. Verder kijken? Bel dan opnieuw.',
			};
			if(type === 'timedout') {
				alertObj.message = 'We hebben geen verbinding kunnen ontdekken met PayNL. Probeer het opnieuw.';
			}
			if(type === 'cancel') {
				alertObj.message = 'Je hebt het maken van verbinding geannuleerd.';
			}
			if(type === 'stop') {
				alertObj.message = 'Je hebt de verbinding gestopt. Vergeet niet de telefoonverbinding te verbreken!';
			}
			this.$store.commit('alertStore/setAlert', alertObj, { root: true });

			document.cookie = "phone_stream_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

			this.$store.commit('resourcesStore/setAccess', {
				access: false,
				is_free: false,
				subscription: [],
			});

			this.$store.commit('mxmStore/clearStream');
			this.$store.commit('ppmStore/setPpmStatus', false);
		},
		getCookie(name) {
			let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
			return match ? match[2] : null;
		},
	},
	mounted() {
		if (this.ppmStatus.action === 'wait') {
			this.startPolling();
		}
	},
	beforeDestroy() {
		this.stopPolling();
	},
};
